import { Notyf } from 'notyf';
import { datadogRum } from '@datadog/browser-rum';

// Datadog
if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: '7e582c18-8d55-4f89-bbf8-1d52f51a3d70',
    clientToken: 'pub04c3af8b301f406a6655a81cf0266308',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'ford-guestxp-microsite',
    env: process.env.RAILS_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.DD_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 5,
    trackUserInteractions: (process.env.RAILS_ENV === 'production'),
    trackResources: (process.env.RAILS_ENV === 'production'),
    trackLongTasks: (process.env.RAILS_ENV === 'production'),
    defaultPrivacyLevel: 'mask-user-input',
  });
}

// -- Setup Global Notifications --

const { dataset, id } = document.body;
const { flashNotice, flashAlert, currentUser } = dataset;
let user; 

if (currentUser) {
  user = JSON.parse(currentUser);
}

const notyf = new Notyf({
  duration: 10000,
  position: {
    x: 'right',
    y: 'top',
  },
  dismissible: true,
  ripple: false
});

window.notyf = notyf;

if (user?.role !== 'viewer') {
  if (flashAlert) {
    notyf.error(flashAlert);
  } else if (flashNotice) {
    notyf.success(flashNotice);
  } else if (localStorage.getItem('notice')) {
    notyf.success(localStorage.getItem('notice'));
    localStorage.removeItem('notice');
  }
}

// -- Import Global Components --
Promise.all([
  import('../javascript/views/shared/user-dropdown'),
  import('../javascript/views/shared/confirm-delete'),
]);

// -- Setup JS Page Imports --

// Currently I tried to use a map-style import but it didn't really like that, so I need to think of a better way to solve this problem
// rather than providing a bunch of if-else-if statements

if (id === 'platforms') {
  if (document.body.classList.contains('live')) {
    import('../javascript/views/platforms/live');
  }
} else if (id === 'platform_guides') {
  if (document.body.classList.contains('edit')) {
    import('../javascript/views/platform_guides/edit');
  } else if (document.body.classList.contains('update')) {
    import('../javascript/views/platform_guides/update');
  } else if (document.body.classList.contains('link_pages')) {
    import('../javascript/views/platform_guides/link_pages');
  } else if (document.body.classList.contains('unlink_pages')) {
    import('../javascript/views/platform_guides/unlink_pages');
  }
} else if (id === 'chapter_pages') {
  if (document.body.classList.contains('new')) {
    import('../javascript/views/chapter_pages/new');
  } else if (document.body.classList.contains('create')) {
    import('../javascript/views/chapter_pages/create');
  } else if (document.body.classList.contains('edit')) {
    import('../javascript/views/chapter_pages/edit');
  } else if (document.body.classList.contains('update')) {
    import('../javascript/views/chapter_pages/update');
  }
} else if (id === 'page_elements') {
  if (document.body.classList.contains('edit')) {
    import('../javascript/views/page_elements/edit');
  } else if (document.body.classList.contains('update')) {
    import('../javascript/views/page_elements/update');
  } else if (document.body.classList.contains('new')) {
    import('../javascript/views/page_elements/new');
  } else if (document.body.classList.contains('create')) {
    import('../javascript/views/page_elements/create');
  }
}
